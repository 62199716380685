<template>
  <div>
    <div v-if="displayLoading">
      <Loading />
    </div>
    <div class="page">
      <div class="page__title"> {{ moduleConfig.carrier_page ? moduleConfig.carrier_page.form_title : '輸入載具發票資訊' }} </div>
      <div class="page__desc" v-if="moduleConfig.carrier_page"> {{ moduleConfig.carrier_page && moduleConfig.carrier_page.form_desc }}</div>

      <div class="form">
        <div class="field">
          <label class="field__label" :class="'field--required'">手機/載具條碼</label>
          <div class="d-flex align-items-center s-space-x-3">
            <input
              class="field__input"
              placeholder=""
              v-model="card_no"
              :class="{ invalid: v$.card_no.$error }"
            />
            <b-badge
              class="px-2"
              :variant="varifyBadgeVariant"
            >
              {{ varifyMessage }}
            </b-badge>
          </div>
          <small class="field__desc">範例：/AB-82CQ</small>
          <div v-if="v$.card_no.$error" class="form__error-msg">
            手機/載具條碼有誤或未填寫，請確認
          </div>
        </div>
        <div class="field" v-if="showCardEncrypt">
          <label class="field__label" :class="'field--required'">手機/載具驗證碼</label>
          <input
            type="password"
            class="field__input"
            placeholder=""
            v-model="card_encrypt"
            :class="{ invalid: v$.card_encrypt.$error }"
          />
          <div v-if="v$.card_encrypt.$error" class="form__error-msg">
            手機/載具驗證碼有誤或未填寫，請確認
          </div>
        </div>

        <div class="field">
          <label class="field__label" :class="'field--required'">手機/載具驗類型</label>
          <SharedSelect
            v-model="card_type"
            :options="card_type_options"
            :class="{ invalid: v$.card_type.$error }"
          />
          <div v-if="v$.card_type.$error" class="form__error-msg">
            資料有誤或未選擇，請確認
          </div>
        </div>
        <div
          v-for="(term, index) in terms"
          :key="index"
          :class="index == 0 ? 's-mb-8' : ''"
          class="field"
        >
          <Accordion :title="term.title">
            <div v-html="term.content"></div>
          </Accordion>
          <Checkbox v-model="checked[index]" :name="'checkbox' + index"
            >已詳閱使用須知並同意相關使用說明</Checkbox
          >
        </div>
      </div>

    </div>
    <div class="page__button">
      <SharedButton class="s-btn-bg-primary" :disabled="!isAllChecked" @click="submit">送出</SharedButton>
    </div>
    <div class="row justify-content-end px-3" v-if="showReset">
      <div class="col-4">
        <SharedButton class="s-btn-outline-primary" @click="showCardEncrypt = true; showReset = false">重置載具</SharedButton>
      </div>
    </div>
  </div>
</template>

<script>
import invoiceApi from '@/apis/liff/v2/invoice'
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import SharedButton from "@/components/Page/Liff/Shared/Button";
import SharedSelect from "@/components/Page/Liff/Shared/Select";
import Loading from "@/components/Page/Liff/Shared/Loading";
import { mapGetters } from "vuex";
import Accordion from "@/components/Page/Liff/Shared/Accordion.vue";
import Checkbox from "@/components/Page/Liff/Shared/Checkbox.vue";

export default {
  components: {
    SharedButton,
    SharedSelect,
    Loading,
    Accordion,
    Checkbox,
  },
  setup: () => ({ v$: useVuelidate({ $lazy: true }) }),
  computed: {
    ...mapGetters({
      getModule: "liffModule/getModule",
    }),
    moduleConfig() {
      return this.getModule('liff_invoice').module_config || {}
    },
    isAllChecked() {
      return this.checked.every((c) => c === true);
    },
  },
  data() {
    return {
      checked: [],
      terms: [],
      varifyBadgeVariant: null,
      varifyMessage: null,
      card_no: null,
      card_encrypt: null,
      card_type: "3J0002",
      valid_status: null,
      showCardEncrypt: true,
      showReset: false,
      displayLoading: false,
      card_type_options: [
        { text: "手機條碼", value: "3J0002", },
        // 暫時限制只能使用手機條碼
        // { text: "悠遊卡", value: "1K0001", },
        // { text: "一卡通", value: "1H0001", },
        // { text: "自然人憑證條碼", value: "CQ0001", },
      ],
    };
  },
  validations() {
    let rules = {
      card_no: { required },
      card_type: {
        required,
        type: (value) => {
          return this.card_type_options.map(x => { return x.value }).includes(value);
        }
      },
    };

    if (this.showCardEncrypt) {
      rules.card_encrypt = { required };
    }

    return rules;
  },
  async created() {
    await this.getCarrierInfo();
    this.setBadgeInfo()
    this.showReset = this.valid_status == 1
    this.fetchTerms()
  },
  methods: {
    async fetchTerms() {
      let terms = this.getModule('liff_invoice')?.module_config?.carrier_page.terms ?? [];
      terms = terms.sort((a, b) => a.order - b.order);

      let checked = [];

      for (let i = 0; i < terms.length; i++) {
        checked[i] = false;
      }

      this.terms = terms;
      this.checked = checked;
    },
    async getCarrierInfo() {
      this.displayLoading = true;
      let { data } = await invoiceApi.getCarrierInfo();

      this.card_no = data.card_no;
      // 當使用這尚未填寫資料時，crad_type 會是 null，此時畫面會顯示手機條碼
      this.card_type = data.card_type || "3J0002";
      this.valid_status = data.valid_status;
      this.displayLoading = false;
    },
    setBadgeInfo() {
      switch (this.valid_status) {
        case 1:
          this.varifyBadgeVariant = "success"
          this.varifyMessage = "驗證成功"
          this.showCardEncrypt = false
          break
        case 0:
          this.varifyBadgeVariant = "danger"
          this.varifyMessage = "驗證失敗"
          break
        case 2:
          this.varifyBadgeVariant = "warning"
          this.varifyMessage = "驗證中"
          break
        default:
          this.varifyBadgeVariant = "warning"
          this.varifyMessage = "尚未驗證"
      }
    },

    async submit() {
      const result = await this.v$.$validate();
      if (!result) {
        return;
      }

      this.displayLoading = true;

      let params = {
        card_no: this.card_no,
        card_encrypt: this.card_encrypt,
        card_type: this.card_type,
      };

      try {
        await invoiceApi.storeCarrierInfo(params);
        this.$swal.fire({
          title: "載具資訊儲存成功",
          type: "success",
        })
        await this.getCarrierInfo();
      } catch (e) {
        console.error(e);
      }

      this.displayLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  padding: 20px 10px;
  &__error-msg {
    color: #fe0000;
    font-size: 12px;
    margin-top: 8px;
  }
}

.field {
  &:not(:last-of-type) {
    margin-bottom: 24px;
  }
  &__label {
    font-weight: 400;
    font-size: 14px;
    color: var(--liff-primary_text_color);
    margin-bottom: 8px;
    display: block;
  }

  &__desc {
    color: var(--liff-secondary_text_color);
    font-size: 12px;
    line-height: 1;
    margin-top: 4px;
  }

  &__input {
    font-weight: 500;
    font-size: 16px;
    color: var(--liff-primary_text_color);
    border: none;
    border-bottom: 1px solid #e5e5ea;
    background: transparent;
    padding-bottom: 8px;
    width: 100%;
    border: 1px solid #e5e5ea;
    border-radius: 5px;
    padding: 8px 12px;
    &::placeholder {
      color: #b7b7b7;
    }
    &:focus {
      border: 1px solid var(--liff-primary_text_color);
    }
    &.invalid {
      border: 1px solid #fe0000;
    }
  }

  &--required {
    &:after {
      content: "*";
      position: relative;
      font-family: "PingFang TC";
      color: var(--s-primary);
      margin-left: 2px;
    }
  }

  &__select-group {
    display: flex;
    > * {
      width: 100%;
      &:not(:last-of-type) {
        margin-right: 8px;
      }
    }
    &.invalid {
      border-bottom: 1px solid #fe0000;
    }
  }
}
</style>
